// Classes
import { ActionField }    from '@/Helpers/Components/DataTable/ActionField'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues } from '@/Constants/AppValues'
import { VueRouter } from '@/Constants/VueRouter'

// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace AppModules {
	export namespace Collections {
		export namespace JoiSchemas {
			export namespace CheckListAddons {
				const JoiSchemaAddonsLabels = {
					_idCheckListAddons: 'Formulario de Evaluación',
					_idAdminCompany:    'Cliente',
					_idStorages:        'Ubicaciones Asociadas',
					attributes:         'Listado de Evaluaciones',
					code:               'Código Formulario',
					groupArticle:       'Grupo Articulo',
					isForClient:        '¿Exclusivo Cliente?',
					name:               'Descripción',
					typeEquipment:      'Tipo de Equipo'
				}

				export const AddCheckListAddon = Joi.object({
					_idAdminCompany: Joi.string().hex().required().label(JoiSchemaAddonsLabels._idAdminCompany),
					_idStorages:     Joi.array().items(Joi.string().hex()).label(JoiSchemaAddonsLabels._idStorages),
					attributes:      Joi.array().items(Joi.string()).label(JoiSchemaAddonsLabels.attributes),
					code:            Joi.string().required().label(JoiSchemaAddonsLabels.code),
					name:            Joi.string().required().label(JoiSchemaAddonsLabels.name),
					groupArticle:    Joi.string().required().label(JoiSchemaAddonsLabels.groupArticle),
					isForClient:     Joi.boolean().required().label(JoiSchemaAddonsLabels.isForClient),
					typeEquipment:   Joi.string().required().label(JoiSchemaAddonsLabels.typeEquipment)
				})

				export const UpdateCheckListAddon = Joi.object({ 
					_idCheckListAddons: Joi.string().hex().required().label(JoiSchemaAddonsLabels._idCheckListAddons),
					_idStorages:        Joi.array().items(Joi.string().hex()).label(JoiSchemaAddonsLabels._idStorages),
					attributes:         Joi.array().items(Joi.string()).label(JoiSchemaAddonsLabels.attributes),
					code:               Joi.string().required().label(JoiSchemaAddonsLabels.code),
					name:               Joi.string().required().label(JoiSchemaAddonsLabels.name),
					groupArticle:       Joi.string().required().label(JoiSchemaAddonsLabels.groupArticle),
					isForClient:        Joi.boolean().required().label(JoiSchemaAddonsLabels.isForClient),
					typeEquipment:      Joi.string().required().label(JoiSchemaAddonsLabels.typeEquipment)
				})
			}

			export namespace CheckSettings {
				const JoiSchemaLabels = {
					_idAdminCompany: '_idAdminCompany',
					_idCompany:      '_idCompany',
					_idResponsible:  '_idResponsible',
					code:            'Código Interno',
					dateValidation:  'Fecha Vigencia'
				}
	
				export const UpsertSettingAuditCode = Joi.object({
					_idAdminCompany: Joi.string().hex().required().label(JoiSchemaLabels._idAdminCompany),
					_idCompany:      Joi.string().hex().required().label(JoiSchemaLabels._idCompany),
					_idResponsible:  Joi.string().hex().required().label(JoiSchemaLabels._idResponsible),
					code:            Joi.string().required().label(JoiSchemaLabels.code),
					dateValidation:  Joi.string().required().label(JoiSchemaLabels.dateValidation)
				})
			}

			export namespace Companies {
				const JoiSchemaCompanyLabels = {
					_idAdminCompany: '_idAdminCompany',
					_idCompany:      '_idCompany',
					code:            'Código',
					rut:             'Rut',
					name:            'Razón Social',
					address:         'Dirección de Facturación',
					region:          'Región',
					commune:         'Comuna',
					type:            'Tipo',
					isClient:        'Es Cliente',
					isValid:         'Esta Activa'
				}

				export const AddCompany = Joi.object({
					_idAdminCompany: Joi.string().required().label(JoiSchemaCompanyLabels._idAdminCompany),
					code:            Joi.string().required().label(JoiSchemaCompanyLabels.code),
					rut:             Joi.string().required().label(JoiSchemaCompanyLabels.rut),
					name:            Joi.string().required().label(JoiSchemaCompanyLabels.name),
					address:         Joi.string().required().label(JoiSchemaCompanyLabels.address),
					region:          Joi.string().required().label(JoiSchemaCompanyLabels.region),
					commune:         Joi.string().required().label(JoiSchemaCompanyLabels.commune),
					type:            Joi.string().required().label(JoiSchemaCompanyLabels.type),
					isClient:        Joi.boolean().required().label(JoiSchemaCompanyLabels.isClient),
				})
				
				export const UpdateCompany = Joi.object({
					_idCompany: Joi.string().hex().required(),
					name:       Joi.string().required().label(JoiSchemaCompanyLabels.name),
					address:    Joi.string().required().label(JoiSchemaCompanyLabels.address),
					commune:    Joi.string().required().label(JoiSchemaCompanyLabels.commune),
					region:     Joi.string().required().label(JoiSchemaCompanyLabels.region),
					isValid:    Joi.boolean().required().label(JoiSchemaCompanyLabels.isValid)
				})
			}

			export namespace Equipments {
				const JoiSchemaEquipmentLabels = {
					_idStorage:     'Ubicación',
					code:           'Código',
					hourmeter:      'Horómetro',
					isValid:        'Estado',
					mark:           'Marca',
					name:           'Descripción',
					groupArticle:   'Grupo Artículo',
					groupEquipment: 'Grupo Equipo',
					serie:          'Serie',
					typeArticle:    'Tipo Artículo',
					typeEquipment:  'Tipo Equipo',
					year:           'Año'
				}

				export const AddEquipment = Joi.object({
					_idStorage:     Joi.string().hex().required().label(JoiSchemaEquipmentLabels._idStorage),
					code:           Joi.string().required().label(JoiSchemaEquipmentLabels.code),
					serie:          Joi.string().required().label(JoiSchemaEquipmentLabels.serie),
					name:           Joi.string().required().label(JoiSchemaEquipmentLabels.name),
					mark:           Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.mark),
					groupArticle:   Joi.string().required().label(JoiSchemaEquipmentLabels.groupArticle),
					typeArticle:    Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.typeArticle),
					groupEquipment: Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.groupEquipment),
					typeEquipment:  Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.typeEquipment),
					year:           Joi.number().required().label(JoiSchemaEquipmentLabels.year),
					hourmeter:      Joi.number().allow(0).label(JoiSchemaEquipmentLabels.hourmeter)
				})
				
				export const UpdateEquipment = Joi.object({
					_idEquipment:   Joi.string().hex().required(),
					_idStorage:     Joi.string().hex().required().label(JoiSchemaEquipmentLabels._idStorage),
					serie:          Joi.string().required().label(JoiSchemaEquipmentLabels.serie),
					name:           Joi.string().required().label(JoiSchemaEquipmentLabels.name),
					mark:           Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.mark),
					groupArticle:   Joi.string().required().label(JoiSchemaEquipmentLabels.groupArticle),
					typeArticle:    Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.typeArticle),
					groupEquipment: Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.groupEquipment),
					typeEquipment:  Joi.string().required().label(JoiSchemaEquipmentLabels.typeEquipment),
					year:           Joi.number().required().label(JoiSchemaEquipmentLabels.year),
					isValid:        Joi.boolean().required().label(JoiSchemaEquipmentLabels.isValid)
				})
			}

			export namespace Installations {
				const JoiSchemaInstallationsLabels = {
					_idInstallation: 'Formulario de Evaluación',
					_idResponsible:  'Responsable',
					_idStorages:     'Ubicaciones Asociadas',
					code:            'Código Instalación',
					name:            'Descripción',
					isValid:         'IsValid'
				}
		
				export const AddInstallation = Joi.object({
					_idResponsible: Joi.string().hex().required().label(JoiSchemaInstallationsLabels._idResponsible),
					_idStorages:    Joi.array().items(Joi.string().hex()).min(1).label(JoiSchemaInstallationsLabels._idStorages),
					code:           Joi.string().required().label(JoiSchemaInstallationsLabels.code),
					name:           Joi.string().required().label(JoiSchemaInstallationsLabels.name)
				})
		
				export const UpdateInstallation = Joi.object({
					_idInstallation: Joi.string().hex().required().label(JoiSchemaInstallationsLabels._idInstallation),
					_idResponsible:  Joi.string().hex().required().label(JoiSchemaInstallationsLabels._idResponsible),
					_idStorages:     Joi.array().items(Joi.string().hex()).min(1).label(JoiSchemaInstallationsLabels._idStorages),
					code:            Joi.string().required().label(JoiSchemaInstallationsLabels.code),
					name:            Joi.string().required().label(JoiSchemaInstallationsLabels.name),
					isValid:         Joi.boolean().required().label(JoiSchemaInstallationsLabels.isValid)
				})
			}

			export namespace Operators {
				const JoiSchemaOperatorBodyNames = {
					_idStorage:     'Ubicación',
					rut:            'Rut',
					name:           'Nombre',
					pLastName:      '1er Apellido',
					mLastName:      '2do Apellido',
					password:       'Contraseña',
					isValid:        'Estado',
					position:       'Cargo',
					licenses:       'Licencia',
					certifications: 'Certificación',
				}

				export const AddOperator = Joi.object({
					_idStorage:     Joi.string().hex().required().label(JoiSchemaOperatorBodyNames._idStorage),
					certifications: Joi.array(),
					licenses:       Joi.array(),
					mLastName:      Joi.string().allow('').min(3).max(32).label(JoiSchemaOperatorBodyNames.mLastName),
					name:           Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.name),
					rut:            Joi.string().required().label(JoiSchemaOperatorBodyNames.rut),
					password:       Joi.string().required().label(JoiSchemaOperatorBodyNames.password),
					pLastName:      Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.pLastName),
					position:       Joi.string().required().label(JoiSchemaOperatorBodyNames.position)
				})
			
				export const UpdateOperator = Joi.object({
					_idOperator:    Joi.string().hex().required(),
					_idStorage:     Joi.string().hex().required().label(JoiSchemaOperatorBodyNames._idStorage),
					certifications: Joi.array(),
					isValid:        Joi.boolean().required().label(JoiSchemaOperatorBodyNames.isValid),
					licenses:       Joi.array(),
					mLastName:      Joi.string().allow('').min(3).max(32),
					name:           Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.name),
					password:       Joi.string(),
					pLastName:      Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.pLastName),
					position:       Joi.string().required().label(JoiSchemaOperatorBodyNames.position)
				})
			}

			export namespace Roles {
				const JoiSchemaRoleLabels = {
					_idAdminCompany: '_idAdminCompany',
					isValid:         'Esta Activo',
					name:            'Rol',
					permissions:     'Permisos'
				}

				export const AddRole = Joi.object({
					_idAdminCompany: Joi.string().hex().required().label(JoiSchemaRoleLabels._idAdminCompany),
					name:            Joi.string().required().label(JoiSchemaRoleLabels.name),
					permissions:     Joi.array().required().label(JoiSchemaRoleLabels.permissions)
				})
				
				export const UpdateRole = Joi.object({
					_idRole:     Joi.string().hex().required(),
					name:        Joi.string().required().label(JoiSchemaRoleLabels.name),
					isValid:     Joi.boolean().required().label(JoiSchemaRoleLabels.isValid),
					permissions: Joi.array().required().label(JoiSchemaRoleLabels.permissions)
				})
			}

			export namespace Services {
				const JoiSchemaLabels = {
					_idService:      'ID Servicio',
					dateProgram:     'Fecha Reparación',
					reasonReject:    'Motivo Rechazo',
					status:          'Estado del Servicio',
					statusEquipment: 'Estatus Equipo',
					technicalName:   'Reasignar Técnico',
					workingDay:      'Jornada Trabajo',
	
				}
				
				export const UpdateService = Joi.object({ 
					_idService:		 Joi.string().required().label(JoiSchemaLabels._idService),
					_idTechnical:    Joi.string().hex().label(JoiSchemaLabels.technicalName),
					dateProgram:     Joi.string().allow('').label(JoiSchemaLabels.dateProgram),
					rasonRejection:  Joi.string().allow(null).label(JoiSchemaLabels.reasonReject),
					statusEquipment: Joi.string().allow('').label(JoiSchemaLabels.statusEquipment),
					status: 		 Joi.string().allow('').label(JoiSchemaLabels.status),
					workingDay:      Joi.string().allow('').label(JoiSchemaLabels.workingDay)
				})
			}

			export namespace Storages {
				const JoiSchemaStorageLabels = {
					_idCompany:   '_idCompany',
					_idTechnical: '_idTechnical',
					_idUserAdmin: '_idUserAdmin',
					codeLocation: 'codeLocation',
					code:         'Código',
					name:         'Nombre',
					address:      'Dirección',
					region:       'Región',
					commune:      'Comuna'
				}

				export const AddStorage = Joi.object({
					_idCompany:   Joi.string().hex().required().label(JoiSchemaStorageLabels._idCompany),
					_idTechnical: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idTechnical),
					_idUserAdmin: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idUserAdmin),
					codeLocation: Joi.string().required().label(JoiSchemaStorageLabels.codeLocation),
					code:         Joi.string().required().label(JoiSchemaStorageLabels.code),
					name:         Joi.string().required().label(JoiSchemaStorageLabels.name),
					address:      Joi.string().min(4).max(255).required().label(JoiSchemaStorageLabels.address),
					commune:      Joi.string().required().label(JoiSchemaStorageLabels.commune),
					region:       Joi.string().required().label(JoiSchemaStorageLabels.region)
				})
				
				export const UpdateStorage = Joi.object({
					_idStorage:   Joi.string().hex().required(),
					_idCompany:   Joi.string().hex().required().label(JoiSchemaStorageLabels._idCompany),
					_idTechnical: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idTechnical),
					_idUserAdmin: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idUserAdmin),
					code:         Joi.string().required().label(JoiSchemaStorageLabels.code),
					name:         Joi.string().required().label(JoiSchemaStorageLabels.name),
					address:      Joi.string().min(4).max(255).required().label(JoiSchemaStorageLabels.address),
					region:       Joi.string().required().label(JoiSchemaStorageLabels.region),
					commune:      Joi.string().required().label(JoiSchemaStorageLabels.commune),
					isValid:      Joi.boolean().required()
				})
			}

			export namespace Users {
				const JoiSchemaLabels = {
					_idAdminCompany: '_idAdminCompany',
					_idCompany:  'Empresa',
					_idRole:     'Rol',
					_idWorkArea: 'Área de Trabajo',
					rut:         'Rut',
					name:        'Nombre',
					pLastName:   'Apellido Paterno',
					mLastName:   'Apellido Materno',
					email:       'Correo',
					phone:       'Fono',
					password:    'Contraseña',
					storages:    'Ubicaciones',
					permissions: 'Permisos'
				}
		
				export const AddUser = Joi.object({
					_idAdminCompany: Joi.string().required().label(JoiSchemaLabels._idAdminCompany),
					_idCompany:  Joi.string().hex().required().label(JoiSchemaLabels._idCompany),
					_idRole:     Joi.string().hex().required().label(JoiSchemaLabels._idRole),
					_idWorkArea: Joi.string().hex().required().label(JoiSchemaLabels._idWorkArea),
					rut:         Joi.string().min(9).max(12).label(JoiSchemaLabels.rut),
					name:        Joi.string().label(JoiSchemaLabels.name),
					pLastName:   Joi.string().required().label(JoiSchemaLabels.pLastName),
					mLastName:   Joi.string().allow(''),
					email:       Joi.string().email({ tlds:{ allow: false } }).required().label(JoiSchemaLabels.email),
					phone:       Joi.array(),
					password:    Joi.string().required().label(JoiSchemaLabels.password),
					storages:    Joi.array().items(Joi.string().hex()),
					permissions: Joi.array()
				})
				
				export const UpdateUser = Joi.object({
					_idUser:     Joi.string().hex().required(),
					_idRole:     Joi.string().hex().required().label(JoiSchemaLabels._idRole),
					_idWorkArea: Joi.string().hex().required().label(JoiSchemaLabels._idWorkArea),
					rut:         Joi.string().min(9).max(12).label(JoiSchemaLabels.rut),
					name:        Joi.string().label(JoiSchemaLabels.name),
					pLastName:   Joi.string().label(JoiSchemaLabels.pLastName),
					mLastName:   Joi.string().allow(''),
					email:       Joi.string().email({ tlds:{ allow: false } }).label(JoiSchemaLabels.email),
					phone:       Joi.array(),
					storages:    Joi.array().items(Joi.string().hex()),
					isValid:      Joi.boolean().required(),
					permissions: Joi.array()
				})
			}

			export namespace WorkAreas {
				const JoiSchemaWorkAreaLabels = {
					_idAdminCompany: '_idAdminCompany',
					_idLeader:       'Líder',
					isValid:         'Esta Activa',
					name:            'Nombre'
				}
	
				export const AddWorkArea = Joi.object({
					_idAdminCompany: Joi.string().hex().required().label(JoiSchemaWorkAreaLabels._idAdminCompany),
					_idLeader:       Joi.string().hex().required().label(JoiSchemaWorkAreaLabels._idLeader),
					name:            Joi.string().required().label(JoiSchemaWorkAreaLabels.name)
				})
				
				export const UpdateWorkArea = Joi.object({
					_idWorkArea: Joi.string().required(),
					_idLeader:   Joi.string().hex().label(JoiSchemaWorkAreaLabels._idLeader),
					name:        Joi.string().required().label(JoiSchemaWorkAreaLabels.name),
					isValid:     Joi.boolean().required().label(JoiSchemaWorkAreaLabels.isValid)
				})
			}

			export namespace Zones {
				const JoiSchemaInternalStorageBodyNames = {
					_idCreator: '_idCreator',
					_idStorage: '_idStorage',
					code:       'Código Ubicación',
					equipments: 'Equipos Asignados',
					name:       'Descripción Ubicación'
				}

				export const AddInternalStorage = Joi.object({
					_idCreator: Joi.string().hex().required().label(JoiSchemaInternalStorageBodyNames._idCreator),
					_idStorage: Joi.string().hex().required().label(JoiSchemaInternalStorageBodyNames._idStorage),
					code:       Joi.string().required().label(JoiSchemaInternalStorageBodyNames.code),
					equipments: Joi.array().items(Joi.string().hex()).min(0).required().label(JoiSchemaInternalStorageBodyNames.equipments),
					name:       Joi.string().required().label(JoiSchemaInternalStorageBodyNames.name)
				})
	
				export const UpdateEquipmentZone = Joi.object({
					_idEquipment: Joi.string().hex().required(),
					_idZone:      Joi.string().hex().required(),
				})
	
				export const UpdateInternalStorage = Joi.object({
					_idZone:    Joi.string().hex().required(),
					equipments: Joi.array().items(Joi.string().hex()).min(0).required().label(JoiSchemaInternalStorageBodyNames.equipments),
					isValid:    Joi.boolean().required(),
					name:       Joi.string().required().label(JoiSchemaInternalStorageBodyNames.name)
				})
			}
		}
	}

	export namespace Components {
		export namespace MenuBar {
			enum AddonsSetupKeyOptionsEnum {
				EVALUATIONS,
				STORAGES,
			}

			export const AddonsSetupItemOptions = [
				{
					key: AddonsSetupKeyOptionsEnum.EVALUATIONS,
					icon: 'list', label: 'Evaluaciones'
				},
				{
					key: AddonsSetupKeyOptionsEnum.STORAGES,
					icon: 'list', label: 'Plantas'
				}
			]

			export const AddonsSetupKeyOptions = {
				...AddonsSetupKeyOptionsEnum
			}

			enum CheckListsKeyOptionsEnum {
				ACTIVES,
				ADDONS,
				CHECKLISTS,
				KPI_CHARTS,
				KPI_OPERATORS,
				SETTING_AUDIT_CODE
			}

			export const CheckListsItemOptions = (shortened: boolean) => [
				{
					key: CheckListsKeyOptionsEnum.ACTIVES,
					icon: 'truck-pickup', label: shortened ? 'Actual' : 'CheckList Actual'
				},
				{
					key: CheckListsKeyOptionsEnum.CHECKLISTS,
					icon: 'list', label: 'Historico'
				},
				{
					key: CheckListsKeyOptionsEnum.ADDONS,
					icon: 'clipboard-check', label: 'Formularios'
				},
				{
					categoryIcon: 'chart-pie', categoryLabel: 'KPI\'s', categoryOpened: false,
					items: [
						{
							key: CheckListsKeyOptionsEnum.KPI_CHARTS,
							icon: 'chart-pie', label: 'CheckLists'
						},
						{
							key: CheckListsKeyOptionsEnum.KPI_OPERATORS,
							icon: 'chart-pie', label: 'Operarios'
						}
					]
				},
				{
					categoryIcon: 'cogs', categoryLabel: shortened ? 'Config.' : 'Configuraciones', categoryOpened: false,
					items: [
						{
							key: CheckListsKeyOptionsEnum.SETTING_AUDIT_CODE,
							icon: 'cogs', label: shortened ? 'Cód. Auditoria' : 'Código Auditoria'
						}
					]
				}
			]

			export const CheckListsKeyOptions = {
				...CheckListsKeyOptionsEnum
			}

			enum StatisticsKeyOptionsEnum {
				ACTIVES_PER_CONTRACT,
				BILLING_PER_MONTH,
				HOURMETER_PER_ACTIVE,
				MAINTENANCE_PER_ACTIVE
			}

			export const StatisticsItemOptions = (shortened: boolean) => [
				{
					key: StatisticsKeyOptionsEnum.ACTIVES_PER_CONTRACT,
					icon: 'truck-pickup', label: shortened ? 'Equipos Por Contrato' : 'Equipos Por Contrato'
				},
				{
					key: StatisticsKeyOptionsEnum.MAINTENANCE_PER_ACTIVE,
					icon: 'truck-pickup', label: 'Mant. Por Maquina'
				},
				{
					key: StatisticsKeyOptionsEnum.BILLING_PER_MONTH,
					icon: 'clipboard-check', label: 'Facturación Por Mes'
				},
				{
					key: StatisticsKeyOptionsEnum.HOURMETER_PER_ACTIVE,
					icon: 'clipboard-check', label: 'Horómetro Por Equipo'
				}
			]

			export const StatisticsKeyOptions = {
				...StatisticsKeyOptionsEnum
			}

			enum SetupCheckListAssociationKeyOptionsEnum {
				CODE,
				STORAGES,
				EVERYTHING
			}

			export const SetupCheckListAssociationItemOptions = [
				{
					key: SetupCheckListAssociationKeyOptionsEnum.CODE,
					icon: 'list', label: 'Código'
				},
				{
					key: SetupCheckListAssociationKeyOptionsEnum.STORAGES,
					icon: 'list', label: 'Plantas'
				},
				{
					key: SetupCheckListAssociationKeyOptionsEnum.EVERYTHING,
					icon: 'list', label: 'Todo'
				}
			]

			export const SetupCheckListAssociationKeyOptions = {
				...SetupCheckListAssociationKeyOptionsEnum
			}

			enum ZonesManagerKeyOptionsEnum {
				INTERNAL_STORAGE,
				INTERNAL_ASSIGNMENT
			}

			export const ZonesManagerItemOptions = [
				{
					key: ZonesManagerKeyOptionsEnum.INTERNAL_STORAGE,
					icon: 'map-marker-alt', label: 'Ubicaciones'
				},
				{
					key: ZonesManagerKeyOptionsEnum.INTERNAL_ASSIGNMENT,
					icon: 'list', label: 'Asignación'
				}
			]

			export const ZonesManagerKeyOptions = {
				...ZonesManagerKeyOptionsEnum
			}
		}

		export namespace SideMenu {
			export const SetupCheckListItems = {
				items: [
					{ type: 'button', label: 'Formularios', componentName: 'Addons', icon: 'building', isActive: true, items: [
						{ name: 'Clients', label: 'Formularios Cliente', isActive: true },
						{ name: 'ByDefault', label: 'Formularios por Defecto', isActive: false },
						{ name: 'ByStorage', label: 'Asignación por Planta', isActive: false }
					] },
					{ type: 'button', label: 'Instalaciones', componentName: 'Facilities', icon: 'warehouse', isActive: false, items: [
						{ name: 'List', label: 'Listado de Instalaciones', isActive: false },
						{ name: 'ByStorage', label: 'Asignación por Planta', isActive: false }
					] }
				]
			}
		}
	}

	export namespace Global {
		export const ExternalModulesList = {
			[AppValues.ObjectIds.Modules.CHECKLISTS]: {
				...PrimitiveTools.Objects.newModuleStructure(1, true, 'CheckLists', 'ModuleCheckList.png'),
				description: 'Inspecciones de equipos e instalaciones, visualización de formularios & indicadores KPI.',
				path: VueRouter.Views.Modules.CHECKLISTS_CPANEL.NAME
			}
		} as ModulesList

		export const SidePanelExternalItems = [
			{ action: VueRouter.Views.Modules.EXTERNAL_CPANEL.NAME, label: 'Listado Modulos', icon: 'dice-d6', selected: true },
			{ action: VueRouter.Views.Modules.OPERATORS_MANAGER.NAME, label: 'Gestión Operadores', icon: 'users-cog', selected: false },
			{ action: VueRouter.Views.Modules.ZONES_MANAGER.NAME, label: 'Gestión Ubicaciones Internas', icon: 'users-cog', selected: false }
		]

		export const InternalModulesList = {
			[AppValues.ObjectIds.Modules.ADMINISTRATION]: {
				...PrimitiveTools.Objects.newModuleStructure(1, true, 'Administración', 'ModuleAdministration.png'),
				description: 'Gestión de clientes, usuarios, roles, áreas de trabajo & visualización de registros (Plantas, Equipos, etc).',
				path: VueRouter.Views.Modules.ADMIN_EXTERNAL_USERS.NAME
			},
			[AppValues.ObjectIds.Modules.SERVICES]: {
				...PrimitiveTools.Objects.newModuleStructure(2, true, 'Solicitudes de Servicio', 'ModuleServices.png'),
				description: 'Gestión de solicitudes de atención, visualización de seguimiento & reasignaciones.',
				path: VueRouter.Views.Modules.SERVICES_CPANEL.NAME
			},
			[AppValues.ObjectIds.Modules.CHECKLISTS]: {
				...PrimitiveTools.Objects.newModuleStructure(3, true, 'CheckLists', 'ModuleCheckList.png'),
				description: 'Inspecciones de equipos e instalaciones, visualización de formularios & indicadores KPI.',
				path: VueRouter.Views.Modules.CHECKLISTS_CPANEL.NAME
			},
			[AppValues.ObjectIds.Modules.SETUP_CHECKLIST]: {
				...PrimitiveTools.Objects.newModuleStructure(4, true, 'Setup CheckList', 'ModuleSetupCheckList.png'),
				description: 'Gestión de formularios CheckList e instalaciones, asignación de registros por plantas.',
				path: VueRouter.Views.Modules.SETUP_CHECKLIST_CPANEL.NAME
			},
			[AppValues.ObjectIds.Modules.STATISTICS]: {
				...PrimitiveTools.Objects.newModuleStructure(5, true, 'Estadísticas', 'ModuleStatistics.png'),
				description: 'Modulo de Estadísticas, KPIs, gestión de informes, graficos y analisis estadístico.',
				path: VueRouter.Views.Modules.STATISTICS_CPANEL.NAME
			}
		} as ModulesList
	}

	export namespace Modules {
		export namespace CheckLists {
			export interface ChartOptionFilters {
				asPercentage?: boolean
				group?: AppValues.PeriodsGroupList
				period?: AppValues.PeriodsChoiceList
			}

			export enum SocketEvents {
				CREATED = 'CheckListCreated'
			}

			export enum TypeActiveNames {
				FACILITY = 'Facility'
			}
			
			export enum TypeActiveTranslationNames {
				FACILITY = 'Instalación'
			}
		}

		export namespace Services {
			export namespace ActionFields {
				const TempColorStates: { [key: string]: string } = {
					Abierto:    'blue',
					Rechazado:  'red',
					'En Curso': 'orange',
					Finalizado: 'green',
				}

				const TempActionFieldStatus = new ActionField('mStatus', 'Estado de solicitud')
					.addItem('state', 'button')
					.setColor('status', TempColorStates)
					.setPermission('STATUS_BUTTON')
					.setTextFrom('status')
				;
		
				export const Services = [
					TempActionFieldStatus,
					new ActionField('actions', 'Acciones')
						.addItem('edit', 'icon')
							.setIcon('pen')
							.setPermission('ACTION_EDIT')
							.setTitle('Editar Servicio')
							.setVariant('green')
				]
			}

			export enum Status {
				ACCEPTED = 'En Curso',
				REJECTED = 'Rechazado'
			}
		}
	}
}

// Interfaces Export
export interface ModulesList {
	[key: string]: ModuleDefinition
}

export interface ModuleDefinition {
	_params: {
		[key: string]: boolean | number
		index: number,
		selectable: boolean,
		visible: boolean
	}
	description: string
	icon: string
	path: string
	title: string
}